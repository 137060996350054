import request, { type TResponse } from '@/utils/request'
import supabase from '@/utils/supabase'
import { type UserDetails } from './user.store'

export function checkUserLoggedIn(): Promise<boolean> {
    return supabase.auth.getUser()
        .then(({ error }) => {
            if (!error) {
                return true
            }
            return false
        })
}

export function requestUserDetails(): Promise<TResponse<UserDetails>> {
    return request<UserDetails>('/user', {
        method: 'get',
    })
}
