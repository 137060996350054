import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ratingRoutes from '@/Ratings/router'
import applicationRoutes from '@/Applications/router'
import { notAuthGuard } from './guards'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        component: () => import('../wrappers/InitWrapper.vue'),
        children: [
            {
                path: '',
                component: () => import('../wrappers/AdminRoleProvider.vue'),
                children: [
                    {
                        path: '',
                        name: 'homepage',
                        component: () => import('../views/HomePage/HomePage.vue'),
                    },
                    ...applicationRoutes,
                ],
            },
            {
                path: 'auth',
                name: 'auth',
                beforeEnter: notAuthGuard,
                component: () => import('../views/Auth/Auth.vue'),
            },
            {
                path: 'about',
                name: 'about',
                component: () => import('../views/About/About.vue'),
            },
            {
                path: 'terms',
                name: 'terms',
                component: () => import('../views/Terms/Terms.vue'),
            },
            {
                path: 'payments',
                name: 'payments',
                component: () => import('../views/Payments/Payments.vue'),
            },
            ratingRoutes,
        ],
    },
]

const router = createRouter({
    history: createWebHistory('/'),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
            }
        }

        if (savedPosition) {
            return savedPosition
        }

        return { top: 0 }
    },
})

export default router
