import { authGuard } from '@/router/guards'

export default [
    {
        path: '/applications',
        beforeEnter: authGuard,
        component: () => import('@/wrappers/AdminRoleProvider.vue'),
        children: [
            {
                path: 'all',
                name: 'admin-applications',
                component: () => import('@/Applications/views/Applications/Applications.vue'),
            },
            {
                path: 'user/:userid',
                name: 'user-applications',
                component: () => import('@/Applications/views/UserApplications/UserApplications.vue'),
            },
            {
                path: ':id',
                name: 'application',
                component: () => import('@/Applications/views/Application/Application.vue'),
            }],
    }, {
        path: '/check-game/:uploadId',
        name: 'check-game',
        beforeEnter: authGuard,
        component: () => import('@/Applications/views/GameCheck/GameCheck.vue'),
    },
]
