import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead, VueHeadMixin } from '@unhead/vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import 'element-plus/theme-chalk/dark/css-vars.css'
import './assets/styles/index.css'
// import elementPlusPlugins from './element-plus-plugins'

const pinia = createPinia()
const head = createHead()
const app = createApp(App)
    .use(i18n)
    .use(router)
    .use(pinia)
    .use(head)
    .mixin(VueHeadMixin)
    .mount('#app')

// elementPlusPlugins.forEach((pl) => app.use(pl))
