import { createClient } from '@supabase/supabase-js'

const {
    VUE_APP_SUPABASE_ANON_KEY = '',
    VUE_APP_SUPABASE_URL = '',
} = process.env

const supabase = createClient(VUE_APP_SUPABASE_URL, VUE_APP_SUPABASE_ANON_KEY)

export default supabase
