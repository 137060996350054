import type { RouteLocationNormalized } from 'vue-router'
import { checkUserLoggedIn, requestUserDetails } from '@/store/user.units'

export function authGuard(to: RouteLocationNormalized) {
    return checkUserLoggedIn()
        .then(async (isUserLoggedIn) => {
            if (isUserLoggedIn) {
                return requestUserDetails()
                    .then((userDetailsResp) => {
                        if (!userDetailsResp?.data?.messengerAccount) {
                            return { name: 'auth', query: { redirect: to.path } }
                        }
                        return undefined
                    })
                    .catch(() => ({ name: 'auth', query: { redirect: to.path } }))
            }
            return { name: 'auth', query: { redirect: to.path } }
        })
}

export function notAuthGuard(to: RouteLocationNormalized) {
    return checkUserLoggedIn()
        .then((isUserLoggedIn) => {
            if (isUserLoggedIn) {
                return requestUserDetails()
                    .then((userDetailsResp) => {
                        if (!userDetailsResp?.data?.messengerAccount) {
                            return undefined
                        }
                        if (Array.isArray(to.query.redirect)) {
                            if (to.query.redirect[0]) {
                                return to.query.redirect[0]
                            }
                            return { name: 'homepage' }
                        }
                        return to.query.redirect || { name: 'homepage' }
                    })
            }
            return undefined
        })
}
