export default {
    path: 'ratings',
    children: [
        {
            path: '',
            name: 'Ratings',
            component: () => import('@/Ratings/views/pages/Table.vue'),
        },
        {
            path: ':platform/games',
            name: 'RatingsGames',
            component: () => import('@/Ratings/views/pages/Table.vue'),
        },
        {
            path: ':platform/games/:slug',
            name: 'RatingsGameDetail',
            component: () => import('@/Ratings/views/pages/GameDetail.vue'),
        },
        {
            path: ':platform/developers',
            name: 'RatingsDevelopers',
            component: () => import('@/Ratings/views/pages/Table.vue'),
        },
    ],
}
